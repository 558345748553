<template>
  <div id="dashboard" class="py-5">

    <div class="vx-row">
      <!-- CARD 1: CONGRATS -->
      <div class="vx-col w-full lg:w-1/3 mb-base">
        <vx-card slot="no-body" class="text-center bg-primary-gradient greet-user">
          <img src="@/assets/images/elements/decore-left.png" class="decore-left" alt="Decore Left" width="200">
          <img src="@/assets/images/elements/decore-right.png" class="decore-right" alt="Decore Right" width="175">
          <feather-icon icon="AwardIcon" class="p-4 mb-2 bg-primary inline-flex rounded-full text-white shadow"
                        svgClasses="h-6 w-6"></feather-icon>
          <h1 class="mb-2 text-white hello-user">{{ $t('Hello') }} {{ activeUserInfo.organisation_name }}</h1>
          <p class="xl:w-3/4 lg:w-4/5 md:w-2/3 w-4/5 mx-auto text-white"></p>
        </vx-card>
      </div>

      <div v-if="activeUserInfo.account_role != 0 && activeUserInfo.internal_role == 0" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
        <balance-card
          :text="activeUserInfo.credit | germanNumberFormat"
          :title="$t('Credit')"
          color="primary"
          type="area"/>
      </div>

      <div v-if="activeUserInfo.account_role != 0 && activeUserInfo.internal_role == 0" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
        <balance-card
          :text="activeUserInfo.balance[activeUserInfo.balance_type] | germanNumberFormat"
          :title="$t('Balance')"
          color="primary"
          type="area"/>
      </div>
      <div v-if="activeUserInfo.account_role == 0 && activeUserInfo.internal_role == 0" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
        <balance-card
          :text="balances.auto_cash_balance | germanNumberFormat"
          :title="$t('AutoCash')"
          color="primary"
          type="area"/>
      </div>

      <div v-if="activeUserInfo.account_role == 0 && activeUserInfo.internal_role == 0" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
        <balance-card
          :text="balances.direct_recharge | germanNumberFormat"
          :title="$t('DirectRecharge')"
          color="primary"
          type="area"/>
      </div>
      <div v-if="activeUserInfo.account_role == 0 && activeUserInfo.internal_role == 0" class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/5 xl:w-1/5 mb-base">
        <balance-card
          :text="balances.international_top_up | germanNumberFormat"
          :title="$t('InternationalTopUp')"
          color="primary"
          type="area"/>
      </div>


      <div
        v-if="false"
        class="vx-col w-full sm:w-full md:w-full lg:w-full xl:w-2/3 mb-base">
        <div class="vx-col w-full">
          <vx-card :title="$t('Reclamations')">
            <div slot="actions">
              <vs-button color="primary"
                         @click="openReclaimsPage"
                         type="flat" >View All</vs-button>
            </div>
            <div slot="no-body" class="mt-4">
              <vs-table-modified
                class="table-dark-inverted"
                v-on:selected="openReclaimDetails"
                v-show="reclaimRequests.length > 0" ref="table"
                :data="reclaimRequests">

                <template slot="thead">
                  <vs-th >{{ $t('ReclamationID') }}</vs-th>
                  <vs-th >{{ $t('CustomerId') }}</vs-th>
                  <vs-th >{{ $t('Pin') }}</vs-th>
                  <vs-th >{{ $t('Batch') }}</vs-th>
                  <vs-th >{{ $t('Status') }}</vs-th>
                  <vs-th >{{ $t('LastUpdated') }}</vs-th>
                  <vs-th >{{ $t('Organisation') }}</vs-th>
                </template>

                <template slot-scope="{data}">
                  <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.reclamation_id }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.pin }}</p>
                    </vs-td>

                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.batch }}</p>
                    </vs-td>


                    <vs-td>
                      <div class="text-center flex justify-center">
                        <vs-chip :color="getStatusColor(tr.status)" class="user-status">{{
                            getStatusText(tr.status) | title
                          }}
                        </vs-chip>
                      </div>
                    </vs-td>


                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.updated | dateFormat }}</p>
                    </vs-td>


                    <vs-td>
                      <p class="user-name font-medium truncate">{{ tr.organisation_name }}</p>
                    </vs-td>

                  </vs-tr>
                  </tbody>
                </template>

              </vs-table-modified>
            </div>

          </vx-card>
        </div>
      </div>

<!--      Offer Image -->

      <div v-if="iseAufladen" class="vx-col w-full mb-base">
        <img class="w-full" style="margin: 10px 0; object-fit: cover;" src="@/assets/offers/poster.jpg"  alt="Offer">
      </div>
      <div v-if="isNotEAufladen && isNotYecpin" class="vx-col w-full mb-base">
        <img class="w-full" style="margin: 10px 0; object-fit: cover;" src="@/assets/offers/offer_warning.jpg"  alt="Offer">
      </div>
      <div v-if="isYecpin" class="vx-col w-full mb-base">
        <img class="w-full" style="margin: 10px 0; object-fit: cover;" src="@/assets/offers/yecpin_banner.jpeg"  alt="Offer">
      </div>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import BalanceCard from '@/views/components/BalanceCard.vue'
import TextBalanceCard from "@/views/components/TextBalanceCard";

export default {
  name: 'Dashboard',
  components: {
    TextBalanceCard,
    StatisticsCardLine,
    BalanceCard,
  },
  data() {
    return {
      fetchingBalances: true,
      balancesError: false,
      balances: {},
      startDate: null,
      endDate: null,
    }
  },
  computed: {
    showOfferImage() {
      return true
      // return window.location.host.indexOf("e-aufladen.") != -1
    },
    isYecpin() {
      // return true
      return window.location.host.indexOf("yecpin.") != -1
    },
    isNotYecpin() {
      // return true
      return window.location.host.indexOf("yecpin.") == -1
    },
    iseAufladen() {
      // return true
      return window.location.host.indexOf("e-aufladen.") != -1
    },
    isNotEAufladen() {
      // return true
      return window.location.host.indexOf("e-aufladen.") == -1
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    reclaimRequests() {
      return this.$store.state.reclamation.reclaimRequests
    },
  },
  methods: {
    getStatusText(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'New'
      if (status === 1) return 'In Progress'
      if (status === 2) return 'Sold'
      if (status === 3) return 'Issue Credit Note'
      if (status === 5) return 'Reject'
      return ''
    },
    getStatusColor(status) {
      // 0 - new, 1 - in-progress, 2 - sold, 3 - issue credit note, 5 - reject
      if (status === 0) return 'danger'
      if (status === 1) return 'warning'
      if (status === 2) return 'success'
      if (status === 3) return 'purple'
      if (status === 5) return 'primary'
      return ''
    },
    fetchDynamicBalances() {
      this.$store.dispatch('auth/fetchUserDynamicBalances')
        .then((data) => {
          this.balancesError = false
          this.fetchingBalances = false
          if (data.message_type == 1) {
            this.balances = data.balances
          } else {
            this.$vs.notify({
              position: 'top-center',
              title: 'Error',
              text: data.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          this.fetchingBalances = false
          this.balancesError = true
          let msg = ''
          try {
            msg = error.response.data.message
          } catch (err) {
            msg = ''
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    fetchReclaimRequests(page = '0') {
      const payload = {
        'page': page,
        'from_date': moment(this.startDate).format('yyyy-MM-DD'),
        'to_date': moment(this.endDate).format('yyyy-MM-DD')
      }
      this.$vs.loading()
      this.$store.dispatch('reclamation/fetchReclaimRequests', {payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
          this.errorFetching = true
          let msg = ''
          try {
            msg = err.response.data.message
          } catch (e) {
            msg = err.message
          }
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: msg,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    openReclaimDetails(reclaim) {
      // reclamation_id
      this.$router.push({ name: 'reclaim-request', params: { reclaimId: reclaim.reclamation_id } })
    },
    openReclaimsPage() {
      // reclamation_id
      this.$router.push({ name: 'reclaim-requests' })
    },
  },
  mounted() {
    this.startDate = new Date()
    this.endDate = moment(new Date()).add(7, 'days').toDate()
    if (this.activeUserInfo.account_role == 0 && this.activeUserInfo.internal_role == 0) {
      this.fetchDynamicBalances()
    }
    // if (this.activeUserInfo.account_role == 2) {
    //   this.fetchReclaimRequests()
    // }
  },
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
