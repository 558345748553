<template>
  <vx-card class="overflow-hidden">
    <div slot="no-body">
      <div class="py-3 truncate text-center ">
        <div class="flex flex-col justify-center">
          <div class="mb-1 font-bold px-2 text-xl">{{ textTitle }}</div>
          <div class="mb-1">{{ text }}</div>
        </div>
        <div class="flex flex-col justify-center" v-if="text1">
          <div class="mb-1 font-bold px-2 text-xl">{{ textTitle1 }}</div>
          <div class="mb-1">{{ text1 }}</div>
        </div>
      </div>
    </div>
  </vx-card>
</template>

<script>

export default {
  props: {
    text: {
      type: [String],
    },
    textTitle: {
      type: [String],
    },
    textTitle1: {
      type: [String],
    },
    text1: {
      type: [String],
    },
    color: {
      type: String,
      default: 'primary',
    },
    colorTo: {
      type: String,
    },
  },
  data() {
    return {}
  },
  mounted() {
  },
}
</script>
